import React, { createContext, useContext, useEffect, useState } from "react";

export const CampaignContext = createContext();

export function CampaignProvider({ children }) {
  const [campaign, setCampaign] = useState(null);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    (async () => {
      setInitializing(true);
      try {
        const savedCampaign = await localStorage.getItem("campaign");

        if (savedCampaign !== null) {
          setCampaign(JSON.parse(savedCampaign));
        } else {
          setCampaign(null);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setInitializing(false);
      }
    })();
  }, []);

  const changeCampaign = (c) => {
    setCampaign(c);
    localStorage.setItem("campaign", JSON.stringify(c));
  };

  return (
    <CampaignContext.Provider value={{ campaign: campaign, changeCampaign: changeCampaign, initializing, setCampaign: setCampaign }}>
      {children}
    </CampaignContext.Provider>
  );
}

export function useCampaign() {
  const context = useContext(CampaignContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
