// CampaignContext.js

import React, { createContext, useContext, useState } from "react";
import { getFirestore, doc, getDoc } from "@firebase/firestore";

const UsersDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const [usersData, setUsersData] = useState(null);

  const fetchUsersData = async (uid) => {
    if (uid) {
      try {
        const db = getFirestore();
        const userDocRef = doc(db, `usersData/${uid}`);
        const docSnap = await getDoc(userDocRef);
        const userData = docSnap.data();

        setUsersData(userData);
      } catch (error) {
        console.error("Failed to fetch campaign data:", error);
      }
    } else {
      setUsersData({});
    }
  };

  return (
    <UsersDataContext.Provider value={{ usersData, fetchUsersData }}>
      {children}
    </UsersDataContext.Provider>
  );
};

export const useUsersDataContext = () => {
  return useContext(UsersDataContext);
};
