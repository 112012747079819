import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute";
import React, { lazy } from "react";
import UnauthorizedRoute from "base-shell/lib/components/UnauthorizedRoute";

const SignUp = lazy(() => import("../pages/SignUp/SignUp"));
const SignIn = lazy(() => import("../pages/SignIn/SignIn"));
const MyAccount = lazy(() => import("../pages/MyAccount/MyAccount"));
const TeamManagement = lazy(() =>
  import("../pages/TeamManagement/TeamManagement")
);
const TeamDetails = lazy(() => import("../pages/TeamDetails/TeamDetails"));
const Users = lazy(() => import("rmw-shell/lib/pages/Users"));
const User = lazy(() => import("rmw-shell/lib/pages/Users/User"));
const Roles = lazy(() => import("rmw-shell/lib/pages/Roles"));
const Role = lazy(() => import("rmw-shell/lib/pages/Roles/Role"));
const EditMembers = lazy(() => import("rmw-shell/lib/pages/EditMembers"));
const EditAdmins = lazy(() => import("rmw-shell/lib/pages/EditAdmins"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const CampaignPermissions = lazy(() =>
  import("../pages/Dashboard/CampaingPermissions")
);
const Campaign = lazy(() => import("../pages/Dashboard/Campaign"));
const EditCampaign = lazy(() => import("../pages/Dashboard/EditCampaing"));
const EditWebsites = lazy(() => import("../pages/Dashboard/EditWebsites"));
const EditEmails = lazy(() => import("../pages/Dashboard/EditEmails"));
const EditBlog = lazy(() => import("../pages/Dashboard/EditBlog"));
const EditSequences = lazy(() => import("../pages/Dashboard/EditSequences"));
const Folder = lazy(() => import("../pages/Folder/Folder"));
const BillingsAndSubscriptions = lazy(() =>
  import("../pages/BillingsAndSubscriptions")
);
const Analytics = lazy(() => import("../pages/Analytics/index"));

const getDefaultRoutes = (appConfig) => {
  return [
    {
      path: "/signin",
      exact: true,
      element: (
        <UnauthorizedRoute>
          <SignIn redirectTo={appConfig?.auth?.redirectTo || "/"} />
        </UnauthorizedRoute>
      ),
    },
    {
      path: "/",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Dashboard />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/signup",
      exact: true,
      element: (
        <UnauthorizedRoute>
          <SignUp redirectTo={appConfig?.auth?.redirectTo || "/"} />
        </UnauthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/edit",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Campaign />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/permissions",
      exact: true,
      element: (
        <AuthorizedRoute>
          <CampaignPermissions />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Campaign />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/edit_campaign",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditCampaign />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/find_websites",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditWebsites />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/find_contacts",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditEmails />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/generate_blog",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditBlog />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/campaign/:campaignId/send_emails",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditSequences />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/folder/:folderId",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Folder />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/analytics/:campaignId",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Analytics />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/edit_members/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditMembers />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/edit_admins/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditAdmins />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/roles",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Roles />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/create_role",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Role />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/roles/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Role />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/roles/:uid/:tab",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Role />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/my_account",
      exact: true,
      element: (
        <AuthorizedRoute>
          <MyAccount />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/team_management",
      exact: true,
      element: (
        <AuthorizedRoute>
          <TeamManagement />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/billings",
      exact: true,
      element: (
        <AuthorizedRoute>
          <BillingsAndSubscriptions />
        </AuthorizedRoute>
      ),
    },

    {
      path: "/team_details",
      exact: true,
      element: (
        <AuthorizedRoute>
          <TeamDetails />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/users",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Users />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/users/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <User />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/users/:uid/:tab",
      exact: true,
      element: (
        <AuthorizedRoute>
          <User />
        </AuthorizedRoute>
      ),
    },
    {
      path: "*",

      element: (
        <AuthorizedRoute>
          <PageNotFound />
        </AuthorizedRoute>
      ),
    },
  ];
};

export { getDefaultRoutes };
