/* eslint-disable react/jsx-key */
import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute";
import React, { lazy } from "react";
import campaignLimit from "../config/campaignLimit";
const About = lazy(() => import("../pages/About"));
const Admin = lazy(() => import("../pages/Demo/Admin"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
//const Project = lazy(() => import("../pages/Dashboard/Campaing"));
const Analytics = lazy(() => import("../pages/Analytics/index"));
const Inbox = lazy(() => import("../pages/Inbox/index"));
const Pipeline = lazy(() => import("../pages/Pipeline/index"));
const Campaign = lazy(() => import("../pages/Dashboard/Campaign"));

const LimitedCampaign = campaignLimit(Campaign, 10);

const routes = [
  {
    path: "/about",
    exact: true,
    element: <About />,
  },
  {
    path: "/dashboard",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/analytics",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Analytics />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/inbox",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Inbox />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/pipeline",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Pipeline />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_campaign",
    exact: true,
    element: (
      <AuthorizedRoute>
        <LimitedCampaign />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/admin",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Admin />
      </AuthorizedRoute>
    ),
  },
];

export default routes;
