import React, { Component } from "react";
import App from "base-shell/lib";
import config from "./config";
import { Provider } from "react-redux";
import store from "./redux/store";
import { EmailProvider } from "./components/EditSteps/EditSequences/EmailContext";
import "react-day-picker/dist/style.css";
import Hotjar from "@hotjar/browser";

import { CampaignProvider } from "providers/campaign";
import { UserDataProvider } from "context/userDataContext";

const siteId = 3814917;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

export default class Demo extends Component {
  render() {
    return (
      <Provider store={store}>
        <CampaignProvider>
          <EmailProvider>
            <UserDataProvider>
              <App config={config} />
            </UserDataProvider>
          </EmailProvider>
        </CampaignProvider>
      </Provider>
    );
  }
}
