import React, { createContext, useEffect, useState, useRef } from "react";
import {
  getFirestore,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";

const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  const [totalEmails, setTotalEmails] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [emailContent, setEmailContent] = useState({
    to: "",
    bcc: "",
    from: "",
    subject: "",
    note: "",
    body: "",
  });
  const [to, setTo] = useState("johndoe@gmail.com");
  const [bcc, setBcc] = useState("");
  const [from, setFrom] = useState("johndoe@gmail.com");
  const [subject, setSubject] = useState("Example Subject");
  const [value, setValue] = useState("");
  const [emails, setEmails] = useState([]);
  const [emailFromError, setEmailFromError] = useState("");
  const [emailToError, setEmailToError] = useState("");
  const [emailSubjectError, setEmailSubjectError] = useState("");
  const initialLoadRef = useRef(true);
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleFromChange = (e) => {
    const emailValue = e.target.value;
    setFrom(emailValue);

    if (!isValidEmail(emailValue) && emailValue) {
      setEmailFromError("Invalid email address");
    } else if (emailValue.toString().trim() === "") {
      setEmailFromError("From email can't be empty");
    } else {
      setEmailFromError("");
    }
  };

  const handleToChange = (e) => {
    const toValue = e.target.value;
    setTo(toValue);

    if (toValue === "") {
      setEmailToError("To field can't be empty");
    } else {
      setEmailToError("");
    }
  };

  const handleBccChange = (e) => {
    const bccValue = e.target.value;
    setBcc(bccValue);
  };

  const handleSubjectChange = (e) => {
    const subjectValue = e.target.value;
    setSubject(subjectValue);

    if (subjectValue.toString().trim() === "") {
      setEmailSubjectError("Subject can't be empty");
    } else {
      setEmailSubjectError("");
    }
  };

  const handleEmailSelection = (emailInfo) => {
    console.log({ emailInfo });
    setEmailContent(emailInfo);
    setTotalEmails(emails.length);
    setIsEditing(false);
  };

  const getCheckedEmailsFromCampaign = async (campaignId) => {
    const db = getFirestore();
    const campaignDocRef = doc(db, `campaings/${campaignId}`);
    const campaignDocSnap = await getDoc(campaignDocRef);

    if (!campaignDocSnap.exists()) {
      console.log("No such campaign!");
      return [];
    }

    const scrapedEmails = campaignDocSnap.data().scrapedEmails || [];
    const checkedEmails = [];
    scrapedEmails.forEach((scrapedEmail) => {
      scrapedEmail.website.emails.forEach((email) => {
        if (email.isChecked) {
          checkedEmails.push(email.email.email);
        }
      });
    });

    return checkedEmails;
  };

  const getEmailsFromCampaign = async (uid, campaignId) => {
    try {
      setIsLoading(true);
      const db = getFirestore();
      const emailsChecked = await getCheckedEmailsFromCampaign(campaignId);
      console.log({ emailsChecked });
      const checkedEmailsString = emailsChecked.join("; ");

      const emailsCampaingsCollection = collection(
        db,
        `campaings/${campaignId}/emails`
      );

      const initialEmailsSnapshot = await getDocs(emailsCampaingsCollection);

      initialEmailsSnapshot.docs.forEach(async (doc) => {
        const emailData = doc.data();
        console.log("Updating document:", doc.id);
        // Check if the new checkedEmailsString is different from the existing 'to' field
        if (emailData.synced !== "updated") {
          emailData.to = checkedEmailsString;
          try {
            const emailDocRef = doc.ref;
            await setDoc(emailDocRef, emailData, { merge: true });
            console.log("Document updated successfully:", doc.id);
          } catch (error) {
            console.error("Error updating document:", doc.id, error);
          }
        }
      });

      const initialEmailsData = initialEmailsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      initialEmailsData.sort((a, b) => a.sortIndex - b.sortIndex);
      setEmails(initialEmailsData);
      if (initialEmailsData.length > 0) {
        const initialEmail = initialEmailsData.find(
          (email) => email.sortIndex === 0
        );
        if (initialEmail) {
          setEmailContent(initialEmail);
        }
        setIsEditing(false);
      }

      const emailsCampaingsUnsubscribe = onSnapshot(
        emailsCampaingsCollection,
        (querySnapshot) => {
          const emailsCampaingsData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setEmails(emailsCampaingsData);
          if (emailsCampaingsData.length > 0) {
            const initialEmail = emailsCampaingsData.find(
              (email) => email.sortIndex === 0
            );
            if (initialEmail) {
              setEmailContent(initialEmail);
            }
            setIsEditing(false);
          }
        },
        (error) => {
          toast.error("Error fetching data", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );

      return () => {
        emailsCampaingsUnsubscribe();
      };
    } catch (error) {
      toast.error("Error fetching data", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTotalEmails(emails.length);
  }, [emails]);

  const updateEmailInCampaign = async (
    uid,
    campaignId,
    emailId,
    updatedEmailData
  ) => {
    try {
      const db = getFirestore();
      const emailDocRef = doc(db, `campaings/${campaignId}/emails`, emailId);

      await updateDoc(emailDocRef, {
        ...updatedEmailData,
        synced: "updated",
      });
      console.log("Email updated with ID: ", emailId);
    } catch (error) {
      console.error("Failed to update email: ", error);
    }
  };

  const addEmailToCampaign = async (uid, campaignId, emailData) => {
    try {
      const emailsChecked = await getCheckedEmailsFromCampaign(campaignId);
      const checkedEmailsString = emailsChecked.join("; ");
      const db = getFirestore();
      emailData.to = checkedEmailsString;

      console.log("Add email called", { emailData });
      console.log({ uid, campaignId, emailData });
      const campaignDocRef = doc(db, `campaings`, campaignId);
      const campaignDoc = await getDoc(campaignDocRef);

      if (!campaignDoc.exists()) {
        console.error("Campaign not found");
        return null;
      }

      const emailCollectionRef = collection(
        db,
        `campaings/${campaignId}/emails`
      );
      const docRef = await addDoc(emailCollectionRef, emailData);

      await setDoc(docRef, { to: checkedEmailsString }, { merge: true });

      setIsEditing(true);
      return docRef.id;
    } catch (error) {
      console.error("Failed to add email: ", error);
      return null;
    }
  };

  const addEmail = async (uid, campaignId, emailData) => {
    const emailId = await addEmailToCampaign(uid, campaignId, emailData);
    if (emailId) {
      const newEmail = { ...emailData, id: emailId };
      setEmailContent(newEmail);
      const newEmails = [...emails, newEmail];
      setEmails(newEmails);
    }
  };

  const updateEmail = async (uid, campaignId, emailId, newEmailData) => {
    emails.forEach((email) => {
      if (email.id === emailId) {
        console.log({ newEmailData });
        updateEmailInCampaign(uid, campaignId, emailId, newEmailData);
      }
    });
  };

  useEffect(() => {
    handleFromChange({ target: { value: emailContent.from } });
    handleToChange({ target: { value: emailContent.to } });
    handleSubjectChange({ target: { value: emailContent.subject } });
    handleBccChange({ target: { value: emailContent.bcc } });
    //eslint-disable-next-line
  }, [emailContent]);
  const stateVariables = {
    emailContent,
    totalEmails,
    emails,
    isEditing,
    emailFromError,
    emailToError,
    to,
    bcc,
    from,
    subject,
    value,
    initialLoadRef,
    isLoading,
  };
  const setters = {
    setEmailContent,
    setTotalEmails,
    setEmails,
    setIsEditing,
    setEmailFromError,
    setEmailToError,
    setTo,
    setBcc,
    setFrom,
    setSubject,
    setValue,
  };

  const errorStates = {
    emailFromError,
    emailToError,
    emailSubjectError,
  };

  const handlers = {
    handleFromChange,
    handleSubjectChange,
    handleToChange,
    handleBccChange,
    updateEmail,
    addEmail,
    getEmailsFromCampaign,
    updateEmailInCampaign,
    handleEmailSelection,
  };
  return (
    <EmailContext.Provider
      value={{
        ...stateVariables,
        ...setters,
        ...errorStates,
        ...handlers,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};

export default EmailContext;
