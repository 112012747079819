import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "@firebase/firestore";
import { useAuth } from "base-shell/lib/providers/Auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const campaignLimit = (Component, limit) => {
  return (props) => {
    const [campaignCount, setCampaignCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        if (auth && auth.uid) {
          const campaignDocRef = collection(
            getFirestore(),
            `users/${auth.uid}/campaings`
          );
          const querySnapshot = await getDocs(campaignDocRef);
          setCampaignCount(querySnapshot.docs.length);
          setLoading(false);
        }
      };

      fetchData();
    }, [auth]);

    useEffect(() => {
      if (!loading && campaignCount >= limit) {
        toast.error("You have reached the limit of campaigns you can create.");
        setRedirect(true);
      }
    }, [loading, campaignCount]);

    useEffect(() => {
      if (redirect) {
        navigate("/");
      }
    }, [redirect, navigate]);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (redirect) {
      return null;
    }

    return <Component {...props} />;
  };
};

export default campaignLimit;
