import { SET_CAMPAIGN_DATA } from "../actionTypes";

const initialState = {
  initialData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_DATA:
      return {
        ...state,
        initialData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
