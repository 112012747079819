import { createStore, combineReducers } from "redux";
import campaignReducer from "./campaing/campaignReducers";
import scrapingReducer from "./scrapingState/scrapingReducers";

const rootReducer = combineReducers({
  campaignReducer,
  scrapingReducer,
});

const store = createStore(rootReducer);

export default store;
