import { lazy } from "react";
import locales from "./locales";
import routes from "./routes";
import themes from "./themes";
import parseLanguages from "base-shell/lib/utils/locale";
import grants from "./grants";
import Loading from "material-ui-shell/lib/components/Loading/Loading";
import {
  defaultUserData,
  isGranted,
  isAnyGranted,
} from "rmw-shell/lib/utils/auth";
import { getDefaultRoutes } from "./getDefaultRoutes";

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: "AIzaSyDjq0bMarfg8wBqFRsl0o9Csyyxv4MJX5I",
        authDomain: "linktastic-dev.firebaseapp.com",
        projectId: "linktastic-dev",
        storageBucket: "linktastic-dev.appspot.com",
        messagingSenderId: "1092320347688",
        appId: "1:1092320347688:web:3e352a9a307157d12b571d",
        measurementId: "G-BKS1LS06LH",
      },
      messaging: {
        publicVapidKey:
          "BEthk1-Qmoh9opZbi1AUZpxANTu6djVRDph4MLpyO2Qk6Dglm1Sa8Yt_pYi4EhYi3Tj-xgLqUktlbNuP_RP6gto",
      },
    },
    devp: {
      initConfig: {
        apiKey: "AIzaSyDjq0bMarfg8wBqFRsl0o9Csyyxv4MJX5I",
        authDomain: "linktastic-dev.firebaseapp.com",
        projectId: "linktastic-dev",
        storageBucket: "linktastic-dev.appspot.com",
        messagingSenderId: "1092320347688",
        appId: "1:1092320347688:web:3e352a9a307157d12b571d",
        measurementId: "G-BKS1LS06LH",
      },
      messaging: {
        publicVapidKey:
          "BEthk1-Qmoh9opZbi1AUZpxANTu6djVRDph4MLpyO2Qk6Dglm1Sa8Yt_pYi4EhYi3Tj-xgLqUktlbNuP_RP6gto",
      },
    },
    dev: {
      initConfig: {
        apiKey: "AIzaSyDjq0bMarfg8wBqFRsl0o9Csyyxv4MJX5I",
        authDomain: "linktastic-dev.firebaseapp.com",
        projectId: "linktastic-dev",
        storageBucket: "linktastic-dev.appspot.com",
        messagingSenderId: "1092320347688",
        appId: "1:1092320347688:web:3e352a9a307157d12b571d",
        measurementId: "G-BKS1LS06LH",
      },
      messaging: {
        publicVapidKey:
          "BCSF2KOsR9kL-K141KkVnn_sawQgRt4sKwUENE3sARIxuoGubecyK_BmNempPqspfnXlypfLnAf33IfQd5FKju4",
      },
    },
    firebaseuiProps: {
      signInOptions: ["google.com", "password"],
    },
  },
  googleMaps: {
    apiKey: "AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g",
  },
  auth: {
    grants,
    redirectTo: "/dashboard",
    persistKey: "base-shell:auth",
    signInURL: "/signin",
    onAuthStateChanged: async (user, auth) => {
      const { getDatabase, ref, onValue, get, update, off } = await import(
        "firebase/database"
      );
      const db = getDatabase();

      try {
        if (user != null) {
          const grantsSnap = await get(ref(db, `user_grants/${user.uid}`));
          const notifcationsDisabledSnap = await get(
            ref(db, `disable_notifications/${user.uid}`)
          );

          const isAdminSnap = await get(ref(db, `admins/${user.uid}`));

          onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
            auth.updateAuth({ grants: snap.val() });
          });
          onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
            auth.updateAuth({ notificationsDisabled: !!snap.val() });
          });
          onValue(ref(db, `admins/${user.uid}`), (snap) => {
            auth.updateAuth({ isAdmin: !!snap.val() });
          });

          auth.updateAuth({
            ...defaultUserData(user),
            grants: grantsSnap.val(),
            notificationsDisabled: notifcationsDisabledSnap.val(),
            isAdmin: !!isAdminSnap.val(),
            isGranted,
            isAnyGranted,
          });

          update(ref(db, `users/${user.uid}`), {
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user.photoURL,
            providers: user.providerData,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            notificationsDisabled: notifcationsDisabledSnap.val(),
          });

          update(ref(db, `user_chats/${user.uid}/public_chat`), {
            displayName: "Public Chat",
            lastMessage: "Group chat",
            path: `group_chat_messages/public_chat`,
          });
        } else {
          off(ref(db));

          auth.setAuth(defaultUserData(user));
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  getDefaultRoutes: getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: "base-shell:locale",
    defaultLocale: parseLanguages(["en", "de", "ru"], "en"),
    onError: (e) => {
      //console.warn(e)

      return;
    },
  },
  menu: {
    MenuContent: lazy(() => import("../components/Menu/MenuContent")),
    MenuHeader: lazy(() => import("../components/Menu/MenuHeader")),
    //https://mui.com/material-ui/react-drawer/

    //  BaseMenu: ({ children }) => {
    //    return <div style={{height:"100%"}}>{children}</div>;
    //  },
  },
  theme: {
    themes,
    defaultThemeID: "default",
    defaultType: "light",
  },
  pages: {
    Dashboard: lazy(() => import("../pages/Dashboard")),
    PageNotFound: lazy(() => import("../pages/PageNotFound")),
  },
  components: {
    Menu: lazy(() => import("material-ui-shell/lib/containers/Menu/Menu")),
    Loading,
  },

  containers: {
    LayoutContainer: lazy(() =>
      import("rmw-shell/lib/containers/LayoutContainer/LayoutContainer")
    ),
  },
  functionEndpoints: {
    nylas: {
      sendEmail:
        "https://us-central1-linktastic-dev.cloudfunctions.net/https-nylasOnRequest-api/nylas/send-email",
      connect:
        "https://us-central1-linktastic-dev.cloudfunctions.net/https-nylasOnRequest-api/nylas/generate-auth-url",
      exchangeToken:
        "https://us-central1-linktastic-dev.cloudfunctions.net/https-nylasOnRequest-api/nylas/exchange-mailbox-token",
      generateAuthUrl:
        "https://us-central1-linktastic-dev.cloudfunctions.net/https-nylasOnRequest-api/nylas/generate-auth-url",
      disconnect:
        "https://us-central1-linktastic-dev.cloudfunctions.net/https-nylasOnRequest-api/nylas/disconnect",
    },
    dataforseo: {
      backlinks:
        "https://us-central1-linktastic-dev.cloudfunctions.net/https-apiBacklinksOnRequest-api/backlinks",
    },
  },
};

export default config;
